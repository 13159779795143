import {Routes} from '@angular/router';
import {VisitorGuard, UserGuard, AdminGuard} from '@core/modules/auth';

export const APP_ROUTES: Routes = [
	{
		path: '',
		canActivate: [VisitorGuard],
		loadChildren: () =>
			import('./views/public/public.routes').then(m => m.PublicRoutingModule),
	},
	{
		path: 'share',
		loadChildren: () =>
			import('./views/share/share.routes').then(m => m.ShareRoutingModule),
	},
	{
		path: 'app',
		canActivate: [UserGuard],
		loadChildren: () =>
			import('./views/user-board/user-board.routes').then(
				m => m.UserBoardRoutingModule
			),
	},

	{
		path: 'vidmizer-board',
		canActivate: [AdminGuard],
		loadChildren: () =>
			import('./views/admin-board/admin-board.routes').then(
				m => m.AdminBoardRoutingModule
			),
	},

	{
		path: '**',
		redirectTo: '/login',
		pathMatch: 'full',
	},
];
