import {NgFor, NgIf} from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	SimpleChanges,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { Router } from '@angular/router';
import { CookieStore } from '@core/modules/storage';
import {TruncatedTextComponent} from '@shared/components/ui';
import {FolderViewDto} from '@shared/modules/library/modules/folders/types/folder-view.dto';
import {VideoDetails} from '@shared/modules/videos/types/video-details';
import {TranslatePipe} from '@shared/pipes';

@Component({
	standalone: true,
	selector: 'green-bread-crumb',
	imports: [
		NgFor,
		NgIf,
		MatButtonModule,
		MatMenuModule,
		MatIconModule,
		TranslatePipe,
		TruncatedTextComponent,
	],
	template: `<span *ngIf="showLibrary && !firstPart.length">
			<a mat-button (click)="onSetCurrentFolder(null)">
				<mat-icon>home</mat-icon>
				<p *ngIf="!data.length">
					{{ 'VIEWS.USER_BOARD.LIBRARY.HEADER.TITLE' | translate }}
				</p>
			</a>
		</span>
		<ng-container *ngIf="firstPart.length > 0">
			<button mat-icon-button [matMenuTriggerFor]="menu">
				<mat-icon>more_horiz</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item>
					<a mat-button (click)="onSetCurrentFolder(null)">
						<p>
							{{ 'VIEWS.USER_BOARD.LIBRARY.HEADER.TITLE' | translate }}
						</p>
					</a>
				</button>
				<button mat-menu-item *ngFor="let bc of firstPart">
					<a mat-button (click)="onSetCurrentFolder(bc)">
						<green-truncated-text
							[value]="bc.name"
							[maxLength]="25"
							class="text-sm" />
					</a>
				</button>
			</mat-menu>
		</ng-container>
		<span class="inline-flex" *ngFor="let bc of secondPart">
			<mat-icon class="!pt-3.5">keyboard_arrow_right</mat-icon>
			<a mat-button (click)="onSetCurrentFolder(bc)">
				<green-truncated-text
					[value]="bc.name"
					[maxLength]="20"
					class="text-sm" />
			</a>
		</span>`,
})
export class BreadCrumbComponent {
	@Input() showLibrary: boolean = true;
	@Input() isAdmin: boolean;
	@Input() data: FolderViewDto[] = [];
	@Input() video: VideoDetails;
	@Output() setCurrentFolder = new EventEmitter<FolderViewDto | null>();

	firstPart: FolderViewDto[] = [];
	secondPart: FolderViewDto[] = [];

	@HostBinding('class') class = 'flex items-center';

	constructor(
		private readonly cookies: CookieStore,
		private readonly router: Router
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.data = changes.data.currentValue;

			this.secondPart = this.data;
			this.firstPart = [];

			if (this.data.length > 3) {
				this.secondPart = this.data.splice(-3);
				this.firstPart = this.data;
			}
		}
	}

	onSetCurrentFolder(folder: FolderViewDto | null): void {
		
	

		this.setCurrentFolder.emit(folder);

		if(this.video) {
			const libraryUrl = this.isAdmin && this.video
			? `/vidmizer-board/account/${this.video.account.uuid}?tab=videos` 
			: '/app/library';

			this.router.navigateByUrl(libraryUrl);
		}
	
	}
}
