import {Validators, FormBuilder} from '@angular/forms';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Observable} from 'rxjs';

import {
	ConfirmationMessageData,
	ConfirmationMessageComponent,
} from '@shared/components/dialogs/confirmation-message.component';
import {
	ConfirmationPassData,
	ConfirmationPassComponent,
} from '@shared/components/dialogs/confirmation-pass.component';

import {NotificationService} from '@core/modules/notification';

/**
 * Provides Factory to create forms and handle their behaviors (success/error messages)
 */
@Injectable()
export class FormFactory {
	constructor(
		private readonly fb: FormBuilder,
		private readonly dialog: MatDialog,
		private readonly notifier: NotificationService
	) {
		this.fb = new FormBuilder();
	}

	private static generateInvalidRequestMessages(err: any): string {
		let errorMessage = 'HTTP_ERRORS.GENERIC';
		if (err.status === 401) {
			errorMessage = 'HTTP_ERRORS.BAD_CREDENTIALS';
		} else if (err.status === 403) {
			errorMessage = 'HTTP_ERRORS.FORBIDDEN';
		} else if (err.status === 409) {
			errorMessage = 'HTTP_ERRORS.ALREADY_EXIST';
		} else if (err.status === 422) {
			errorMessage = 'HTTP_ERRORS.INVALID_EMAIL';
		} else if (typeof err === 'string') {
			errorMessage = err;
		} else {
			console.error(err);
		}
		return errorMessage;
	}

	build = (form: any, options?: Validators | null | undefined) =>
		this.fb.group(form, options);

	handleSuccessMessages = (message: string) => this.notifier.notify(message);

	handleErrorMessages = (err: any) =>
		this.notifier.notify(FormFactory.generateInvalidRequestMessages(err));

	confirmationStep = (msg: string): Observable<ConfirmationMessageData> =>
		this.openDialog(ConfirmationMessageComponent, msg);

	confirmationPass = (msg: string): Observable<ConfirmationPassData> =>
		this.openDialog(ConfirmationPassComponent, msg);

	private openDialog = (comp: any, msg: string): Observable<any> =>
		this.dialog
			.open(comp, {disableClose: true, data: {message: msg}})
			.afterClosed();
}
