// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	domain: 'greenencoder',
	url: 'https://app-dev.greenencoder.net/',
	root_url: 'https://dev.greenencoder.net/api/v1',
	mercure_url: 'https://gc-dev-mercure.greenencoder.net/.well-known/mercure',
	lyra: {
		username: '39700025',
		password: 'testpassword_nFqHEmBqdpeq9dXpBqQw3A8d7eGv3lx8FeUD6RPwVQM5E',
		appId: '69876357',
		publicKey: 'testpublickey_llYQpwaaqUvqyxdJliCpCxDH5EeSy1WMmNUv5JX8ntuqf',
	},
	demo_player:
		'https://storage.gra.cloud.ovh.net/v1/AUTH_cf288dd96a8a4bc5bbc0ba258e1b93b8/media-storage-dev/demo-player.mp4',
	icon_path: 'assets/img/icons/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
