import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {TranslatePipe} from './translate.pipe';

@Pipe({
	standalone: true,
	pure: false,
	name: 'inputError',
})
export class InputErrorPipe implements PipeTransform {
	constructor(private translate: TranslatePipe) {}

	transform(control: AbstractControl): string {
		if (!control.errors) return '';

		const errors = Object.entries(control.errors).map(([key, value]) => {
			switch (key) {
				case 'min':
					return this.translate.transform(
						'FORMS.CONTROLS.ERRORS.MINIMUM', 
						{ value: value.min }
					);
				case 'max':
					return this.translate.transform(
						'FORMS.CONTROLS.ERRORS.MAXIMUM',
						{ value: value.max }
					);
				case 'minlength':
					return this.translate.transform(
						'FORMS.CONTROLS.ERRORS.MINIMUM_LENGTH',
						{ value: value.requiredLength }
					);
				case 'maxlength':
					return this.translate.transform(
						'FORMS.CONTROLS.ERRORS.MAXIMUM_LENGTH',
						{ value: value.requiredLength }
					);
				case 'required':
					return this.translate.transform('FORMS.CONTROLS.ERRORS.REQUIRED');
				case 'resolution':
					return this.translate.transform('FORMS.CONTROLS.ERRORS.UNKNOWN_RESOLUTION');
				default:
					return this.translate.transform('FORMS.CONTROLS.ERRORS.INVALID');
			}
		});

		return errors.join('. ');
	}
}
